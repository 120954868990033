<template>
    <div class="main">
        <div class="search">
            <el-select size="mini" style="width: 160px" v-model="search.type" placeholder="종류선택" @change="getIpList">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option :value="managerConst.IPType.IP_TYPE_BLOCK" label="회원IP 차단">회원아이피 차단</el-option>
                <el-option :value="managerConst.IPType.IP_TYPE_ALLOWE_MANAGER_LOGIN" label="관리자 로그인 허용IP">관리자 로그인 허용IP</el-option>
            </el-select>

            <el-button size="mini" type="success" @click="edit()" style="margin-left: 20px">IP등록</el-button>
        </div>
        <div class="data">
            <el-table
                    :data="ipList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="종류"
                        width="150">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === managerConst.IPType.IP_TYPE_BLOCK">회원블럭IP</span>
                        <span v-if="scope.row.type === managerConst.IPType.IP_TYPE_ALLOWE_MANAGER_LOGIN">관리자 로그인 허용 IP</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="IP"
                        width="300">
                    <template slot-scope="scope">
                        <span>{{scope.row.ip}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="내용"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{scope.row.content}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label=""
                        width="140">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteIp(scope.row.id)">삭제</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
            <el-dialog title="IP등록" :visible.sync="visiable" style="width: 100%">
                <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap">

                    <div style="width: 33%">
                        <h5>종류선택</h5>
                        <el-select size="mini" style="width: 80%" v-model="ip.type" placeholder="종류선택">
                            <el-option :value="managerConst.IPType.IP_TYPE_BLOCK" label="회원IP 차단">회원아이피 차단</el-option>
                            <el-option :value="managerConst.IPType.IP_TYPE_ALLOWE_MANAGER_LOGIN" label="관리자 로그인 허용IP">관리자 로그인 허용IP</el-option>
                        </el-select>
                    </div>
                    <div style="width: 33%">
                        <h5>IP</h5>
                        <el-input size="mini" style="width: 80%" placeholder="IP"  v-model="ip.ip"></el-input>
                    </div>
                    <div style="width: 33%">
                        <h5>블럭/등록 사유</h5>
                        <el-input size="mini" style="width: 80%" placeholder="블럭/등록 사유"  v-model="ip.content"></el-input>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="visiable = false">취소</el-button>
                    <el-button type="primary" @click="save">확인</el-button>
                </div>

            </el-dialog>
        </div>
    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import managerConst from "../../common/administrator/managerConst";
    import {manager} from "../../common/administrator/managerMixin";
    import {Loading} from "element-ui";
    import {
        deleteCusTemp,
        getCusTempList,
        saveCusTemp,
        updateCusTemp
    } from "../../network/manager/customerTemplateRequest";
    import {delIp, getIpList, saveIp} from "../../network/manager/ipcontainerRequest";

    export default {
        name: "ManagerIP",
        mixins: [manager],
        components: {ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                visiable: false,
                activeNames: 0,
                search:{},
                ip: {},
                ipList: [],
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
            }
        },
        methods: {
            edit() {
                this.ip = {}
                this.visiable = true
            },
            getIpList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getIpList(this.search,this.pageNum,this.pageSize).then(res => {
                    this.ipList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                saveIp(this.ip).then(res => {
                    loadingInstance.close()
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '등록이 완료되였습니다'
                        });
                        this.getIpList()
                        this.visiable = false
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },
            deleteIp(id) {
                this.$confirm('삭제 하시겠습니까?', '삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    delIp(id).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getIpList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getIpList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getIpList();
            },
        },
        created() {
            this.getIpList()

        },
        watch: {}
    }
</script>

<style scoped>

</style>